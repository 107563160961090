import React from "react"

const CustomerQuote = ({ person, profilePicture, children }) => (
  <div
    className="border border-white/10 rounded-lg p-6 md:p-12 bg-transparent
    transition hover:bg-lilac/5 hover:border-lilac duration-1000 hover:text-white
    sm:text-2xl italic text-center text-dark-grey shadow-md"
  >
    {children}
    <div className="mt-4 flex gap-3 items-center justify-center not-italic">
      <img src={profilePicture} className="rounded-full w-8" />
      <span className="text-white text-sm">{person}</span>
    </div>
  </div>
)

export default CustomerQuote
