import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PropTypes from "prop-types"

import addToMailchimp from "gatsby-plugin-mailchimp"

import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import ReactModal from "react-modal"

import Layout from "../components/layout"
import Security from "../components/security"
import SEO from "../components/seo"

import LogoWall from "../components/LogoWall"

import EngagementAutomation from "../images/landing/engagement.png"
import TargetingAutomation from "../images/landing/targeting.png"
import OptimisationAutomation from "../images/use-cases/revops.png"

import researchAutomationMp4 from "../videos/research-automation.mp4"

import customerDataPng from "../images/homepage/customer-data.png"
import marketResearchPng from "../images/homepage/market-research.png"
import processAutomationPng from "../images/homepage/process-automation.png"

import multiStepPng from "../images/homepage/multi-step.png"
import accuratePng from "../images/homepage/accurate.png"
import multiSourcePng from "../images/homepage/multi-source.png"
import kgPng from "../images/homepage/kg.png"
import modelAgnosticPng from "../images/homepage/model-agnostic.png"

import importEverythingPng from "../images/homepage/import-everything.png"

import quoteBg1 from "../images/quote-bg-1-min.png"
import quoteBg2 from "../images/quote-bg-2-min.png"

import { IconReplace, IconSpeedboat } from "@tabler/icons"
import CTA from "../components/cta"
import HeadlessCTA from "../components/headlessCTA"
import customerWorkfront from "../images/customers/Adobe_Workfront_lockup_no_shadow_reversed_0.png"
import customerGoCardless from "../images/customers/GoCardless_Logo_Negative_RGB.png"

import { TypeAnimation } from "react-type-animation"

import JodyProfile from "../images/jody-clark.jpeg"
import AnaProfile from "../images/anastasia-ably.jpeg"
import RomainProfile from "../images/romain-stackone.jpeg"

import CustomerQuote from "../components/CustomerQuote"

ReactModal.setAppElement("#___gatsby")

function PostCard({ date, title, blurb, url, category, image }) {
  const color = image
    ? "bg-black"
    : ["bg-lilac", "bg-blue", "bg-salmon", "bg-emerald-300", "bg-amber-300"][
        Math.floor(Math.random() * 4)
      ]
  return (
    <Link to={url} className="flex-1">
      <div className="max-w-3xl mx-auto p-4 md:p-6 group rounded-xl hover:shadow-2xl hover:bg-neutral-700/50 transition duration-500">
        <p className="text-dark-grey mb-4 font-sans text-sm">
          {date} &mdash; {category}
        </p>
        <div className={color + " rounded-lg aspect-[1280/720] mb-6"}>
          <GatsbyImage image={getImage(image)} className="rounded-lg h-full" />
        </div>
        <div className="text-white group-hover:text-lilac transition font-medium text-xl mb-4">
          {title}
        </div>
      </div>
    </Link>
  )
}

const LatestBlog = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(
        sort: { fields: updatedAt, order: DESC }
        limit: 3
      ) {
        nodes {
          contentful_id
          author
          category
          title
          updatedAt(formatString: "MMMM DD, YYYY")
          slug
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    }
  `)

  return (
    <section className="py-12 my-12 border-t border-b border-white/10">
      <div className="container mx-auto px-4 md:px-6 py-6">
        <h3 className="text-4xl font-bold text-white">Latest Resources</h3>
      </div>

      <div className="container mx-auto flex flex-col lg:flex-row gap-2">
        {pageQuery.allContentfulBlogPost.nodes.map(post => (
          <PostCard
            key={post.contentful_id}
            date={post.updatedAt}
            title={post.title}
            image={post.featuredImage}
            url={"/blog/" + post.slug}
            category={post.category}
          />
        ))}
      </div>
    </section>
  )
}

const WallOfLoveQuote = ({ person, profilePicture, children }) => (
  <div
    className="border border-white/10 rounded-lg p-6 bg-transparent
    transition hover:bg-lilac/25 hover:border-lilac duration-1000 hover:text-white
    text-white/60 shadow-md"
  >
    {children}
    <div className="mt-4 flex gap-3 items-center not-italic">
      <span className="text-xs text-dark-grey">{person}</span>
    </div>
  </div>
)

const Quotes = () => {
  return (
    <section id="customers" className="my-32">
      <div className="lg:text-center mx-auto flex flex-col gap-4 mb-12 container px-4 lg:px-8 text-white">
        <div className="font-bold text-3xl md:text-6xl max-w-3xl lg:mx-auto">
          Learn why teams rely on Tactic
        </div>
        <div className="text-medium-grey text-lg md:text-xl max-w-4xl lg:mx-auto">
          Trusted by hypergrowth startups and Fortune 500
        </div>
      </div>
      <div className="grid md:grid-cols-3 container mx-auto px-2 sm:px-6 gap-6">
        <div className="flex flex-col gap-6">
          <WallOfLoveQuote person="Anastasia Yushkina, Marketing & Automation, Ably">
            [Tactic is] a tool that replaced 3. We onboarded Tactic as an
            additional sales intelligence for b2b outbound activities. What
            really has sparked our interest were the custom Datapoints and
            Signals that we could set up, unique and tailored for our very
            technical products at Ably.
          </WallOfLoveQuote>

          <WallOfLoveQuote person="Jody Clark, VP Sales Development, GoCardless">
            With Tactic, we save 10 hours a week per salesperson. Thousands of
            answers are found, filtered and ranked based on their priorities -
            so they know where to start.
          </WallOfLoveQuote>

          <WallOfLoveQuote person="Theresa Amouzou, Account Executive, Brainlabs Digital">
            Tactic helped me save significant time in my prospecting process, by
            bringing up specific personas and insights that correlate with
            Brainlab's products and services. It helps me cut right to the chase
            and focus on the right prospects.
          </WallOfLoveQuote>
        </div>
        <div className="flex flex-col gap-6">
          <WallOfLoveQuote person="Tom Ward, Enterprise SDR, GoCardless">
            Undoubtedly my favourite tool in our tech stack! Tactic helps me
            automate searches that would normally take me hours, finding
            relevant merchants that need support with their bank payments. It's
            cool that we are starting to see the real world use-cases for AI /
            Chat GPT - and this is a fantastic example!
          </WallOfLoveQuote>
          <WallOfLoveQuote
            person="Andrew Hall, Director of Marketing, Adobe Workfront"
            profilePicture={RomainProfile}
          >
            Tactic drives our outbound strategy and deep insight into our target
            accounts, and has been a key piece of technology that helped us
            scale in the past year.
          </WallOfLoveQuote>

          <WallOfLoveQuote person="Manager, Revenue Systems, Leading Sales Tech Unicorn">
            We used Tactic for their technographic data internationally. The
            results outperformed our initial test, which we were happy about.
          </WallOfLoveQuote>
        </div>
        <div className="flex flex-col gap-6">
          <WallOfLoveQuote person="Karl Rafidimanana, Go-To-Market, Elba">
            I really love the product. I have saved countless of hours compared
            to my legacy data process.
          </WallOfLoveQuote>

          <WallOfLoveQuote person="George Gilks, Enterprise Sales, Adobe Workfront">
            With Tactic my research is 5x faster. In a few clicks, I can mine
            the entire internet for relevant and targeted account actions,
            combine with my Salesforce data, leading to larger and higher
            quality pipeline.
          </WallOfLoveQuote>

          <WallOfLoveQuote person="Manager, Data and Marketing Technology, B2B Fintech">
            We cannot find this data anywhere else, and Tactic enabled us to
            generate it exactly to our specifications. It is deeply integrated
            into our go-to-market data strategy and account scoring model.
          </WallOfLoveQuote>
        </div>
      </div>
    </section>
  )
}

const BeforeAfter = () => {
  const teamPillClass =
    "rounded-lg px-3 py-1 text-medium-grey my-3 bg-white/10 border border-medium-grey hover:text-blue hover:border-blue hover:bg-blue/10 transition shadow"

  return (
    <section
      className="container mx-auto flex flex-col bg-gradient-to-b from-[#222326] to-black rounded-xl"
      id="why-tactic"
    >
      <div className="p-8 lg:p-16">
        <div className="max-w-5xl flex flex-col gap-8">
          <div className="text-lilac uppercase tracking-widest">
            What is tactic?
          </div>
          <div className="text-white font-light text-3xl lg:text-6xl">
            Tactic partners with you to build data and insights into your
            biggest competitive advantage.
          </div>
        </div>
        <div className="text-dark-grey mt-12">Tactic for:</div>
        <div className="flex flex-col lg:flex-row lg:gap-2 text-xl">
          <Link className={teamPillClass} to="/solutions#strategy">
            Strategy
          </Link>
          <Link className={teamPillClass} to="/solutions#marketing">
            Marketing
          </Link>
          <Link className={teamPillClass} to="/solutions#sales">
            Sales
          </Link>
          <Link className={teamPillClass} to="/solutions#customer-success">
            Customer Success
          </Link>
          <Link className={teamPillClass} to="/solutions#partnerships">
            Partnerships
          </Link>
          <Link className={teamPillClass} to="/solutions#operations-enablement">
            Operations &amp; Enablement
          </Link>
        </div>
      </div>

      <hr className="border-dark-grey/25" />

      {/* Target Accounts */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center">
        <div className="flex-1 xl:max-w-3xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Targeting automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Better target accounts
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Source every account, team, initiative and prospect in your TAM.
              Clean and enrich them with a customised, unlimited selection of
              data.
            </p>
            <p>Score, qualify and segment every company on the planet. </p>
            <p>
              Distribute the right segments to the right channel: inbound,
              outbound, partners, product-led and more.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconReplace /> Replaces self sourcing accounts,
              ZoomInfo/Crunchbase/BVD, data outsourcing, data quality projects.
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img src={TargetingAutomation} className="rounded-l-lg shadow-2xl" />
        </div>
      </section>

      {/* Engagement Insights */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center gap-4">
        <div className="flex-1">
          <img
            src={EngagementAutomation}
            className="mb-8 xl:mb-0 rounded-r-lg shadow-2xl"
          />
        </div>
        <div className="flex-1 xl:max-w-2xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Engagement automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Personalise every buyer interaction
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Prioritise accounts based on propensity to buy, ACV, triggers and
              intent.
            </p>
            <p>
              Engage them using detailed insights on goals, pain points, and
              initiatives of each prospect.
            </p>
            <p>
              Close more revenue by building relationships with the entire
              buying committee and connecting your solution to the buyers'
              strategy.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconReplace /> Replaces scraping event lists, media monitoring,
              ABM agencies, manual account plans and maps, territory
              prioritisation spreadsheets.
            </div>
          </div>
        </div>
      </section>

      {/* Target Accounts */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center">
        <div className="flex-1 xl:max-w-2xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Optimisation automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Apply learnings quickly
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Replicate high performing reps and campaigns. Find new attributes
              and signals to increase win rates and ACV. Deploy findings into
              your territory plans, marketing strategies and sales tactics.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconSpeedboat /> Accelerates strategic revenue operations
              projects, territory planning and book building.
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img src={OptimisationAutomation} className="xl:pr-16 shadow-2xl" />
        </div>
      </section>
    </section>
  )
}

const WhatIsTactic = () => {
  return (
    <section className="py-12 md:py-36 text-white bg-lilac-15">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="lg:text-center mx-auto flex flex-col gap-4 container px-4 lg:px-8 text-white">
          <div className="font-bold text-3xl md:text-6xl xl:text-9xl max-w-8xl lg:mx-auto">
            Better than GPT
          </div>
          <div className="text-medium-grey text-lg md:text-xl max-w-4xl lg:mx-auto">
            When using LLMs to build out a centralised customer data engine,
            Tactic's models are fine tuned to outperform GPT on build customer
            360 profiles.
          </div>
        </div>
      </div>

      <div className="container mx-auto my-24 px-4 lg:px-8 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2">
        <div className="bg-white/10 rounded-lg">
          <div className="text-lg font-semibold p-5">Multi-step reasoning</div>
          <img src={multiStepPng} />
        </div>
        <div className="bg-white/10 rounded-lg">
          <div className="text-lg font-semibold p-5">Accurate</div>
          <img src={accuratePng} />
        </div>
        <div className="bg-white/10 rounded-lg">
          <div className="text-lg font-semibold p-5">Multi source</div>
          <img src={multiSourcePng} />
        </div>
        <div className="bg-white/10 rounded-lg">
          <div className="text-lg font-semibold p-5">
            Knowledge graph based reasoning
          </div>
          <img src={kgPng} />
        </div>
        <div className="bg-white/10 rounded-lg">
          <div className="text-lg font-semibold p-5">Model agnostic</div>
          <img src={modelAgnosticPng} />
        </div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row gap-12 px-4 lg:px-8 md:py-16">
        <div className="flex-1 md:w-5/12">
          <div className="text-white font-light text-3xl lg:text-6xl my-12">
            Import everything
          </div>
          <div className="text-dark-grey lg:text-xl">
            <div className="pb-4">
              Import data everywhere, so that you can predict best fit accounts,
              upsell candidates, and churn risks. Scrape the internet, upload
              files, connect your systems like Salesforce, and buy 3rd party
              data on our data marketplace.
            </div>
          </div>
        </div>
        <div className="flex-1 md:w-7/12">
          <img src={importEverythingPng} />
        </div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row-reverse gap-12 px-4 lg:px-8 md:py-16">
        <div className="flex-1 md:w-5/12">
          <div className="text-white font-light text-3xl lg:text-6xl my-12">
            Simple UX
          </div>
          <div className="text-dark-grey lg:text-xl">
            <div className="pb-4">
              Easy to use and proven UX that enables users to get structured
              answers, no coding needed. Get started in hours, not months.
            </div>
          </div>
        </div>
        <div className="flex-1 md:w-7/12">
          <video
            width="1920"
            height="1080"
            autoPlay
            muted
            loop
            className="rounded-lg shadow-lg shadow-lilac"
          >
            <source src={researchAutomationMp4} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container mx-auto my-4 px-4 lg:px-8">
        <CustomerQuote
          person="Romain, Co-founder @ Stackone"
          profilePicture={RomainProfile}
        >
          “Tactic has improved the speed at which we source and target the most
          relevant accounts. It's the only provider that has all the data we
          need, in an easy to use interface that we can iterate with. Definitely
          would recommend!”
        </CustomerQuote>
      </div>
    </section>
  )
}

const Blurb = () => {
  return (
    <section className="container mx-auto flex flex-col gap-24 px-4 lg:px-8 py-24">
      <div className="max-w-4xl flex flex-col gap-8">
        <div className="text-white font-light text-3xl lg:text-5xl">
          No-code custom AI builder for real time web, CRM, and API scraping
        </div>
        <div className="text-dark-grey lg:text-lg">
          Automate audience, prospect and customer data across Google, Linkedin,
          job posts, annual reports and more. Deliver your team higher fit
          target accounts, a wider range of intent triggers, and deeper
          insights. Close more deals with less people.
        </div>
        <div className="flex gap-12 my-12">
          <div className="text-soft-tones-lime">
            <div className="text-3xl lg:text-6xl">+40%</div>
            <div>efficiency</div>
          </div>
          <div className="text-soft-tones-blue">
            <div className="text-3xl lg:text-6xl">+79%</div>
            <div>outputs</div>
          </div>
          <div className="text-soft-tones-amber">
            <div className="text-3xl lg:text-6xl">10+ hrs</div>
            <div>saved per worker</div>
          </div>
        </div>
        <Link className="text-blue" to="/gocardless">
          Read customer stories here &rarr;
        </Link>
      </div>

      <CustomerQuote
        person="Anastasia Yushkina, Ably"
        profilePicture={AnaProfile}
      >
        “The moment we launched, the expectations were exceeded immediately. We
        had the actual signals we needed that were not available in very generic
        datasets coming from other tools”
      </CustomerQuote>
    </section>
  )
}

const UseCases = () => {
  return (
    <section className="flex flex-col py-24 border-b border-white/10">
      <div className="container mx-auto flex flex-col gap-8 px-4 lg:px-8">
        <div className="text-white font-light text-3xl lg:text-7xl">
          Use cases
        </div>
        <div
          className="border border-white/50 rounded-lg flex flex-col xl:flex-row
          divide-y xl:divide-y-0 xl:divide-x divide-white/50 my-12"
        >
          <div className="p-6 text-white flex-1">
            <div className="text-3xl mb-8">Account Targeting</div>
            <Link className="text-blue" to="/product">
              Learn more
            </Link>
          </div>
          <div className="p-6 text-white flex-1">
            <div className="text-3xl mb-8">Sales Intelligence</div>
            <Link className="text-blue" to="/product">
              Learn more
            </Link>
          </div>
          <div className="p-6 text-white flex-1">
            <div className="text-3xl mb-8">Customer + Market Research</div>
            <Link className="text-blue" to="/generative-insights">
              Learn more
            </Link>
          </div>
        </div>

        <CustomerQuote
          person="Jody Clark, GoCardless"
          profilePicture={JodyProfile}
        >
          "Tactic is truly an extension of our data capability, and enables us
          to deliver high quality data to sales, marketing, customer success,
          partnerships, and more with a small team."
        </CustomerQuote>
      </div>
    </section>
  )
}

const RedStepDot = ({ step }) => (
  <span className="h-10 w-10 bg-red-500 font-bold py-2 text-center rounded-full block">
    {step}
  </span>
)

RedStepDot.propTypes = {
  step: PropTypes.number,
}

const IndexPage = () => {
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, {
      FULLNAME: "Tactic Landing Page",
      NOTES: `Request Demo`,
      FUNCTION: "Sales, Marketing, or Growth",
    })
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
        }
        if (data.result === "success") {
          setIsSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout variant="home">
      <SEO
        title="Tactic: Generative insights"
        description="Generate new customer insights using AI from web, internal, and 3rd party data in a few clicks."
      />

      <div className="container mx-auto py-8 md:py-12 xl:py-16">
        <div
          className="flex flex-col gap-12 px-6 max-w-5xl"
          id="hero-container"
        >
          <h1
            className="font-medium text-white min-h-[250px] md:min-h-0 text-5xl md:text-7xl tracking-tight"
            style={{
              mixBlendMode: "overlay",
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Generate{" "}
            <TypeAnimation
              sequence={[
                "target accounts",
                1000,
                "intent signals",
                1000,
                "prospect insights",
                1000,
                "customer research",
                1000,
              ]}
              wrapper="span"
              cursor={false}
              repeat={Infinity}
              preRenderFirstString
              omitDeletionAnimation
            />
            <br />
            unique to your business
          </h1>
          <h2
            className="text-medium-grey lg:text-xl"
            style={{
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Generate new customer insights using AI from web, internal, and 3rd
            party data in a few clicks. Increase revenue with better targeting,
            and reduce time spent on research and analysis.
          </h2>
          <HeadlessCTA />
        </div>
      </div>

      <section>
        <CarouselProvider
          className=""
          visibleSlides={1}
          totalSlides={3}
          naturalSlideHeight={1311}
          naturalSlideWidth={635}
          isIntrinsicHeight
          isPlaying={true}
          infinite={true}
          interval={3500}
        >
          <div className="flex gap-12 p-8 text-white justify-center md:text-center text-left">
            <Dot className="focus:outline-none" slide={0}>
              Customer Data
            </Dot>
            <Dot className="focus:outline-none" slide={1}>
              Market Research
            </Dot>
            <Dot className="focus:outline-none" slide={2}>
              Process Automation
            </Dot>
          </div>
          <Slider>
            <Slide index={0}>
              <div className="max-w-[1311px] mx-auto">
                <img src={customerDataPng} />
              </div>
            </Slide>
            <Slide index={1}>
              <div className="max-w-[1311px] mx-auto">
                <img src={marketResearchPng} />
              </div>
            </Slide>
            <Slide index={2}>
              <div className="max-w-[1311px] mx-auto">
                <img src={processAutomationPng} />
              </div>
            </Slide>
          </Slider>
        </CarouselProvider>
      </section>

      <section className=" border-b border-white/10">
        <div className="container mx-auto mt-12 flex">
          <div className="flex-1"></div>
          <div className="text-center text-lilac">
            Trusted by hypergrowth startups and Fortune 500
            <LogoWall />
          </div>
          <div className="flex-1"></div>
        </div>
      </section>

      <Blurb />
      <UseCases />
      <WhatIsTactic />

      <Quotes />
      <CTA />
      <Security />
      <LatestBlog />
    </Layout>
  )
}

export default IndexPage
